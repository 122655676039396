@import '../../../src/styles/Screens.scss';

.action-link-tile{
    display: flex;
    flex-direction: column;
    padding: 24px 24px 32px;
    gap: 24px;
    background: #FFFFFF;
    border: 1px solid #E9EEFA;
    box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
    border-radius: 8px;


    @media only screen and (min-width: $phone-max-width) {
        flex: 1 1 0;
        width: 0;
    }

    .cta-text {
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
            font-family: 'Literata', serif;
            font-size: 22px;
            letter-spacing: -0.005em;
            margin-bottom: 8px;
            color: #041C4D;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 18px;
            }
        }

        p {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #0C0033;
            margin-bottom: 16px;
            flex-grow: 1;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 12px;
                line-height: 150%;
            }
        }

        a {
            font-weight: 700;
            font-size: 16px;
            line-height: 125%;
            color: #8651CC;
        }
    }
}
