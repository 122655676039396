@import '../../../../../src/styles/Screens.scss';

.user-list-container {
    margin-top: 80px;
    margin-bottom: 80px;

    @media only screen and (max-width: $phone-max-width) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @media only screen and (max-width: $phone-max-width) {
            flex-direction: column;
            align-items: start;
        }

        h1 {
            font-family: 'Literata';
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            letter-spacing: -0.54px;
            color: #09399A;
        }

        .controls {
            display: flex;
            flex-direction: row;
            gap: 8px;

            > * {
                height: 36px;
            }

            .secondary {
                background-color: #EEEDF0;
            }

            .text {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .user-list-table {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-top: 40px;
        margin-bottom: 40px;
        border-collapse: collapse;
        width: 100%;

        @media only screen and (max-width: $phone-max-width) {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        thead>tr {

            th.user-list-name,
            th.user-list-email,
            th.user-list-role,
            th.user-list-npi,
            th.user-list-actions {
                font-size: 18px;
            }

            @media only screen and (max-width: $phone-max-width) {
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;

                th.user-list-name,
                th.user-list-role,
                th.user-list-email {
                    border-bottom: none;
                }

                th.user-list-name,
                th.user-list-email,
                th.user-list-role,
                th.user-list-npi {
                    font-size: 16px;
                    display: flex;
                    padding: 0px;
                }

                th.user-list-npi {
                    padding-bottom: 16px;
                }

                th.user-list-actions {
                    font-size: 16px;
                    display: table-cell;
                    vertical-align: top;
                    padding: 0px
                }
            }
        }

        tr>th,
        tr>td {
            border-bottom: 1px solid #CECCD6;
        }

        @media only screen and (max-width: $phone-max-width) {
            tbody>tr {
                font-size: 12px;
                line-height: 150%;

                td {
                    border-bottom: none;
                    padding: 0px;
                    padding-bottom: 8px;
                }

                td.user-list-name {
                    padding-left: 0px;
                    padding-top: 16px;
                }

                td.user-list-npi {
                    padding-bottom: 16px;
                }

                td.user-list-actions,
                td.user-list-npi {
                    border-bottom: 1px solid #CECCD6;
                }

                td.user-list-name,
                td.user-list-email,
                td.user-list-role,
                td.user-list-npi {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding-right: 16px;
                    word-wrap: always;
                }

                td.user-list-actions {
                    .action-wrapper {
                        flex-direction: column;
                    }

                    width: 100%;
                    padding: 16px 0px;

                    .rewards-link,
                    .edit-user-link {
                        padding-bottom: 8px;
                    }

                    .rewards-link,
                    .edit-user-link,
                    .delete-user {
                        padding-right: 0px;
                    }
                }
            }
        }

        tr>th {
            text-align: left;
        }

        th:first-child,
        td:first-child {
            padding-left: 16px;
        }

        th:last-child,
        td:last-child {
            padding-right: 16px;
        }

        th {
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.09px;
            color: #6D6685;
        }

        th,
        td {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        td {
            color: #0C0033;

        }

        .user-list-actions {
            .action-wrapper {
                display: flex;
            }

            .rewards-link,
            .edit-user-link,
            .delete-user {
                display: flex;
                color: #8651CC;
                padding: 0px;
                padding-right: 16px;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #8651CC;

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        .page-back:disabled {
            color: #A09EA6;
        }

        .page-back {
            height: 24px;
            width: 24px;
        }

        .page-number {
            height: 24px;
            width: 24px;
        }

        .active {
            background-color: #E7E5EB;
            border-radius: 6px;
        }

        .page-forward {
            height: 24px;
            width: 24px;
        }
    }
}
