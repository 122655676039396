@import '../../../src/styles/Screens.scss';

.submission-complete-container {
    font-family: 'Lato', sans-serif;
    max-width: 800px;
    color: #032246;
    background-color: #fff;
    margin: 82px 100px 146px 100px;
    padding: 80px 112px 112px 112px;
    box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
    border-radius: 8px;

    // add new margin and padding for less than desktop sizes
    @media only screen and (max-width: 1000px) {
        margin: 24px 0px 35px 0px;
        padding: 40px 24px;
    }

    h1 {
        font-family: 'Literata', serif;
        font-weight: 600;
        font-size: 42px;
        line-height: 130%;
        letter-spacing: -0.015em;
        color: #09399A;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
            line-height: 117.5%;
            letter-spacing: -0.01em;
        }
    }

    >p {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.005em;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 16px;
        }
    }

    .cta {
        display: flex;
        flex-direction: row;
        padding: 24px 24px 32px;
        background: #F7F6F8;
        border-radius: 8px;

        @media only screen and (max-width: $phone-max-width) {
            flex-direction: column;
        }

        .cta-image {
            margin-right: 24px;

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 24px;
            }
        }

        svg {
            height: 92px;
        }

        .cta-text {
            display: flex;
            flex-direction: column;

            h2 {
                font-family: 'Literata', serif;
                font-weight: 600;
                font-size: 22px;
                line-height: 130%;
                letter-spacing: -0.005em;
                color: #041C4D;
                margin-bottom: 8px;

                @media only screen and (max-width: $phone-max-width) {
                    font-size: 18px;
                    line-height: 132.5%;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #0C0033;
                margin-bottom: 16px;

                @media only screen and (max-width: $phone-max-width) {
                    font-size: 12px;
                    line-height: 150%;
                }
            }

            a {
                font-weight: 700;
                font-size: 16px;
                line-height: 125%;
                color: #0B47C1;
            }
        }
    }
}
