@import '../../../src/styles/Screens.scss';

.case-search {
    font-family: 'Lato', sans-serif;
    color: #0C0033;
    background-color: #fff;
    margin: 82px 100px 112px 100px;
    padding: 80px 112px 112px 112px;
    box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
    border-radius: 8px;

    // add new margin and padding for less than desktop sizes
    @media only screen and (max-width: 1000px) {
        margin: 24px 0px 35px 0px;
        padding: 40px 24px 96px 24px;
    }

    h1 {
        font-family: 'Literata', serif;
        font-style: normal;
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #09399A;
        line-height: 130%;

        /* identical to box height, or 55px */
        letter-spacing: -0.015em;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
            line-height: 117.5%;
        }
    }

    .description {
        font-size: 18px;
        margin-bottom: 16px;
        line-height: 150%;
        font-weight: 400;

        /* or 27px */
        letter-spacing: -0.005em;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 16px;
        }
    }

    .instruction {
        font-size: 12px;
        margin-bottom: 40px;

        &::before {
            content: "*";
            color: #BE4335;
        }

        @media only screen and (max-width: $phone-max-width) {
            margin-bottom: 40px;
        }
    }

    form {
        display: flex;
        flex-direction: column;

        >label {
            font-family: 'Literata', serif;
            font-size: 21px;
            margin-bottom: 4px;

            &:after {
                content: "*";
                color: #BE4335;
            }

            @media only screen and (max-width: $phone-max-width) {
                font-size: 18px;
            }
        }

        >p {
            color: #6D6685;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 16px;
            line-height: 140%;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 12px;
            }
        }

        .ui.input {
            font-size: 16px;
            line-height: 125%;
        }

        >.error {
            color: #BE4335;
            margin-bottom: 0px;
        }

        >.input {
            margin-bottom: 12px;
        }

        >.button {
            margin-top: 40px;
        }
    }
}
