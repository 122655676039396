@import './Colors';
@import './Screens';
@import './Constants';
@import './Custom';

.page-container {
    background: white;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 15px;
    min-height: 100px;
    padding: 40px 48px;
    position: relative;
}

.page-container::before {
    bottom: 0;
    box-shadow: 0 24px 32px rgba(12, 0, 51, .1);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

textarea::-webkit-input-placeholder {
    opacity: .7;
}

.title.loading {
    clear: both;
    display: inline-block;
    line-height: 32px;
}

.base-container {
    padding-top: 124px;
    min-height: calc(100vh - #{$bottom-navigation-height} - #{$container-margin});
}

@media only screen and (max-width: $phone-max-width) {
    .page-container {
        padding: 20px;
    }

    .base-container {
        padding-top: 76px;
    }
}
