.chip {
    color: #fff !important;
    font-size: 12px !important;
    padding: 4px 8px;
    background: #8651CC;
    border-radius: 100px;
    width: fit-content;

    &.purple {
        background: #8651CC;
    }

    &.blue {
        background: #0B47C1;
    }

    &.orange {
        background: #B97456;
    }

    &.light-blue {
        background: #E7EDF9;
        color: #09399A !important;
    }
}
