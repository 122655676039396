@import '../../../src/styles/Screens.scss';

.consensus-form-menu {
    position: fixed;
    top: 203px;
    width: 190px;
    margin: 0px auto 0px -205px;
    padding: 10px 0px 0px 0px;
    background-color: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);

    .menu-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            margin: 0px 7px 0px 8px;
            vertical-align: middle;

            path {
                fill: #09399A;
            }
        }

        .consensus-form-menu-title {
            font-family: 'Literata';
            font-style: normal;
            font-size: 16px;
            line-height: 142%;
            color: #09399A;
            font-weight: 600;
        }
    }

    ul {
        padding-left: 30px;
    }

    li {
        margin-bottom: 15px;
        list-style-type: circle;
        color: #09399A;
    }

    a {
        color: #09399A;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }

    .active-link {
        font-weight: bold;
        list-style-type: disc;
    }

    .invalid {
        color: #FF0000;
    }

    a svg {
        vertical-align: middle;
        display: inline-block;
    }

    @media only screen and (max-width: $tablet-max-width) {
        display: block;
        position: sticky;
        top: unset;
        bottom: 100px;
        left: 0px;
        width: 100%;
        margin: 0px;
        padding: 20px 20px 10px 20px;
        z-index: 100;
        border: unset;
        border-radius: unset;
        box-shadow: 4px 8px 8px rgba(12, 0, 51, 0.08), 0px -4px 5px rgba(39, 42, 44, 0.1);
    }
}

.consensus-summary-footer {
    display: flex;
    position: sticky;
    bottom: 0px;
    left: 0px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 4px 8px 8px rgba(12, 0, 51, 0.08), 0px -4px 5px rgba(39, 42, 44, 0.1);
    z-index: 10;

    @media only screen and (max-width: $tablet-max-width) {
        padding: 20px 0px;
    }

    .preview-button {
        margin: 0px 0px 0px 2%;
    }

    .submit-button {
        margin: 0px 0px 0px 2%;
    }

    .errors-text {
        display: flex;
        flex-grow: 1;
        margin: 0px 0px 0px 2%;

        @media only screen and (max-width: $tablet-max-width) {
            width: 61%;
            height: 40px;
            margin: 0px 2%;
            overflow-y: hidden;
        }
    }

    .menu-button {
        display: none;
        vertical-align: middle;
        margin: 0px 2% 0px 0px;

        @media only screen and (max-width: $tablet-max-width) {
            display: flex;
            justify-content: center;
        }
    }
}
