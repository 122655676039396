$red: #ff0048;
$orange: #FFC000;;
$yellow: #FFD700;
$olive: #32CD32;
$green: #016936;
$light-green:#00D3A7;
$teal: #008080;
$blue: #0E6EB8;
$light-blue: #64c6e7;
$violet: #EE82EE;
$purple: #B413EC;
$pink: #FF1493;
$brown: #A52A2A;
$grey: #A0A0A0;
$light-grey: #DDDDDD;
$black: #0C0033;
$white: #FFFFFF;
$light-grey2: #F2F2F2;
$teal: #00B2CB;
