.question-header {
    .ui.icon.button.action-button {
        width: 32px;
        height: 32px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

td {
    .ui.icon.button.action-button {
        width: 20px;
        height: 20px;
        font-size: 16px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgba($black, 0.8);
        &:hover {
            color: $light-blue;
        }
    }
}

.ui.button.action-button {
    font-size: 20px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: $black;
    transition: all 200ms ease;
    &:hover {
        color: $teal;
        border: 0;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
    &:focus {
        border: 0;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
}

.ui.button.expand-button {
    font-size: 28px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: $teal;
    transition: all 200ms ease;
    margin-left: 16px;
}

.ui.button.expand-button.hover {
    font-size: 28px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: $white;
    transition: all 200ms ease;
}

.dropdown.programs-dropdown {
    height: 24px;
    width: 60px;
    padding: 6px 12px !important;
    display: inline-flex !important;
    justify-content: space-between !important;

    div.text {
        color: rgba(0, 0, 0, 0.8) !important;
        font-weight: 600;
    }

    .menu>.item {
        padding: 0.5rem !important;
    }

    .menu>.selected {
        background-color: $light-blue !important;
        color: $white !important;
    }
}

.ui.button {
    border-radius: 4px;
}

.negative-hover {
    &:hover, &:focus {
        color: $red !important;
    }
    >:last-child {
        &:hover, &:focus {
            color: $red !important;
        }
    }
}

.ui.right.pointing.basic.label:before {
    z-index: 0;
}

.dropdown-extra {
    div.visible.menu.transition {
        >:first-child {
            color: rgba(115,115,115,.87);
        }
    }
}
