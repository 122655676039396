@import '../../../src/styles/Screens.scss';

.summary-preview-container {
    button {
        margin-bottom: 10px;
    }


    .export-summary-container {
        display: flex;
    }

    .export-summary {
        margin-top: 10px;
        margin-right: 10px;
    }

    .action-link-tiles{
        padding: 0 220px 96px 220px;

        @media only screen and (max-width: $tablet-max-width) {
            padding: 0 80px 96px 80px;
        }

        @media only screen and (max-width: $phone-max-width) {
            flex-direction: column;
            padding: 0 16px 96px 16px;
        }
    }

    .consensus-header {
        padding: 48px 220px;

        @media only screen and (max-width: $tablet-max-width) {
            padding: 80px;
        }

        @media only screen and (max-width: $phone-max-width) {
            padding: 16px;
        }
    }

    .consensus-body {
        padding: 48px 220px;

        @media only screen and (max-width: $tablet-max-width) {
            padding: 80px;
        }

        @media only screen and (max-width: $phone-max-width) {
            padding: 16px;
        }
    }
}
