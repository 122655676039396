@import '../../styles/Screens';

.profile {
    @media only screen and (min-width: $phone-max-width) {
        .page-container .expanded {
            margin-left: -40px;
            margin-right: -40px;
        }
    }
}
