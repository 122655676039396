@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
    .title-h1 {
        @apply text-blue-600 font-title md:text-[36px]/[46.8px] font-semibold md:tracking-[-0.54px] text-[28px]/[32.9px] tracking-[-0.28px];
    }
    .title-h2 {
        @apply text-blue-600 font-title md:text-[28px]/[36.4px] font-semibold md:tracking-[-0.28px] text-[24px]/[26.4px] tracking-[-0.12px];
    }
    .title-h3 {
        @apply text-blue-600 font-title md:text-[22px]/[28.6px] font-semibold md:tracking-[-0.11px] text-[18px]/[23.8px];
    }
    .title-h4 {
        @apply text-blue-600 font-title md:text-[18px]/[23.4px] font-semibold text-[16px]/[22.7px];
    }
    .body-xl {
        @apply text-purple-gray-800 font-body md:text-[28px]/[36.4px] font-normal md:tracking-[-0.42px] text-[22px]/[26.4px] tracking-[-0.22px];
    }
    .body-l {
        @apply text-purple-gray-800 font-body md:text-[22px]/[28.6px] font-normal md:tracking-[-0.22px] text-[20px]/[30px] tracking-[-0.1px];
    }
    .body-m {
        @apply text-purple-gray-800 font-body md:text-[18px]/[27px] font-normal md:tracking-[-0.09px] text-[16px]/[24px];
    }
    .body-s {
        @apply text-purple-gray-800 font-body md:text-[14px]/[19.6px] font-normal text-[12px]/[18px];
    }
    .body-xs {
        @apply text-purple-gray-800 font-body md:text-[12px]/[14.4px] font-normal text-[11px]/[16px];
    }
}
