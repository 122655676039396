// All scss imports should be placed here
// App.scss is considered entry style file
@import './Base';
@import './Form';
@import './Modal';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital@0;1&family=Literata:ital,opsz,wght@0,7..72,600;1,7..72,600&display=swap');

#root {
    height: 100%;
}

.top-separator {
    background-color: $light-blue;
    height: $top-separator-height;
    width: 100%;
    z-index: 101; // same as navigation
}
