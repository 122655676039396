@import './Colors';

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 0;
}

.form-warning {
  color: $orange;
}
