@import '../../../src/styles/Screens.scss';

.preview-container {
    max-width: 960px;
    font-family: 'Lato', sans-serif;

    >h1 {
        font-family: 'Literata', serif;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 16px;
        color: #09399A;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
            margin-bottom: 16px;
        }
    }

    >p {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 40px;
        line-height: 143%;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 18px;
            margin-bottom: 40px;
            line-height: 132.5%;
        }
    }
}

.consensus-container {
    font-family: 'Lato', sans-serif;
    box-shadow: 0px 6px 10px rgba(39, 42, 44, 0.14), 0px 1px 18px rgba(39, 42, 44, 0.12), 0px 3px 5px -1px rgba(39, 42, 44, 0.2);
    color: #032246;
    background: #FFFFFF;
    position: relative;

    .consensus-header {
        background: #F7F6F8;
        padding: 48px;

        @media only screen and (max-width: $phone-max-width) {
            padding: 16px;
        }
    }

    h1 {
        font-family: 'Literata', serif;
        font-size: 36px;
        line-height: 130%;
        letter-spacing: -0.015em;
        margin-bottom: 20px;
        font-weight: 600;
        color: #0C0033;
        position: relative;
        z-index: 2;
        word-break: break-word;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
            margin-bottom: 24px;
        }
    }

    h2 {
        font-family: 'Literata', serif;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.015em;
        margin-bottom: 32px;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 24px;
            margin-bottom: 24px;
        }
    }

    p, ol, ul {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 40px;
        word-break: break-word;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 16px;
            margin-bottom: 24px;
            line-height: 142%;
        }
    }

    ol, ul {
        list-style-position: outside;
        padding-left: 20px;
        margin-top: 0;
    }

    blockquote {
        padding: 10px 20px;
        margin: 0 0 20px;
        font-size: 17.5px;
        border-left: 5px solid #eee;
    }

    .author {
        padding: 0px;
        margin-bottom: 30px;
        position: relative;
        z-index: 2;

        display: flex;
        flex-flow: row wrap;
    }

    .author-avatar {
        width: 80px;
        height: 80px;
        margin: 0px 20px 0px 0px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
    }

    .author-info {
        padding: 10px 0px 0px 0px;
    }

    .author-name {
        color: #09399A;
        font-size: 20px;
    }

    .author-titles {
        margin: 8px 0px 0px 0px;
    }

    .quote {
        border-left: 4px solid #3C6CCD;
        background: rgba(231, 237, 249, 0.5);
        padding: 32px;
        margin-bottom: 24px;

        display: flex;
        flex-flow: column wrap;

        @media only screen and (max-width: $phone-max-width) {
            margin-bottom: 16px;
            padding: 16px;
        }
    }

    .quote-avatar {
        width: 60px;
        height: 60px;
        margin: 0px 10px 0px 0px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
    }

    .quote-info-avatar {
        display: flex;
        flex-flow: row wrap;
    }

    .quote-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 16px;
        letter-spacing: -0.01em;

        p {
          margin-bottom: 16px;
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
        }

        @media only screen and (max-width: $phone-max-width) {
            font-size: 16px;
            line-height: 142%;
        }
    }

    .quote-author {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: #3C6CCD;
        margin: 20px 0px 0px 0px;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 14px;
            line-height: 142%;
        }
    }

    .case {
        padding: 36px 32px 42px 32px;
        background: #FFFFFF;
        border: 1px solid #EEEDF0;
        box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
        border-radius: 16px;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: $phone-max-width) {
            margin-bottom: 40px;
            padding: 16px;
        }

        &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 28px;

            @media only screen and (max-width: $phone-max-width) {
                flex-direction: column;
                margin-bottom: 16px;
            }

            .chip {
                margin-bottom: 0px;

                @media only screen and (max-width: $phone-max-width) {
                    margin-bottom: 12px;
                }
            }

            .case-data {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #6D6685;
                margin-bottom: 0px;
            }
        }

        &-text {
            margin-bottom: 0px;
            line-height: 130%;
            letter-spacing: -0.01em;
            p.has-strong {
                font-family: 'Literata', serif;
                margin-bottom: 16px;
                color: #0B47C1;
                font-weight: 600;
            }
        }
    }

    .consensus-body {
        margin-left: 0;
        margin-right: 0;
        padding: 48px 220px;

        @media only screen and (max-width: $phone-max-width) {

            padding: 24px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .title, .title > p {
            color: #0052CC;
            font-weight: 400;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.01em;
            word-break: break-word;
            margin-bottom: 40px;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 22px;
                margin-bottom: 24px;
                line-height: 110%;
            }
        }

        .chip {
            margin-bottom: 16px;
        }

        .quotes-container {
            margin-bottom: 64px;

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 32px;
            }
        }

        .definitions,
        .references {
            color: #6D6685;
            margin-bottom: 40px;

            >h2 {
                font-size: 16px;
                line-height: 125%;
                margin-bottom: 16px;
                font-weight: 600;

                @media only screen and (max-width: $phone-max-width) {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 142%;
                    margin-bottom: 8px;
                }
            }

            li>p {
                font-size: 14px;
                margin-bottom: 0px
            }

            ul {
                list-style: none;
                padding-left: 0px;
            }

            ol {
                list-style: decimal;
                padding-left: 15px;
            }
        }
    }

    #consensus-main-content {
        scroll-margin-top: 100px;
    }

    #topic-selection-container {
        margin: 0 0 50px 0;

        #topic-select-heading {
            margin-bottom: 10px;
        }

        #topic-anchors {
            display: flex;
            column-gap: 10px;
            row-gap: 10px;

            a {
                padding: 10px 20px;
                color: #FFFFFF;
                background: #0B47C1;
                font-size: 14px;
                border-radius: 100px;
                text-align: center;
                width: 20%;
                display: flex;
                align-items: center;

                span {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        @media only screen and (max-width: $phone-max-width) {
            #topic-anchors {
                flex-direction: column;

                a {
                    width: 100%;
                }
            }
        }
    }

    #section-topic-container {
        position: relative;
        display: flex;
        column-gap: 1rem;
        flex-wrap: wrap;
    }

    .section {
        scroll-margin-top: 100px;
        margin-bottom: 40px;

        p:last-child {
            margin-bottom: 20px;
        }
    }

    .back-to-top-btn-box {
        text-align: right;
    }

    .back-to-top-btn {
        font-size: 15px;
    }

    #sources-container {
        display: flex;
        column-gap: 5px;
        color: #6D6685;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 30px;

        .source-information {
            color: #6D6685;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            margin-bottom: 10px;
        }
    }
}
