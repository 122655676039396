.menu_item {
    position: relative;

    .item {
        &:before {
            width: 0 !important;
        }
    }

    .active.item {
        background-color: rgba(0,0,0,0) !important;
        border-bottom: 3px solid #54BDD1;
    }

    .regular-spacing {
        border-bottom: 3px solid rgba(0,0,0,0);
        position: absolute;
    }
}
