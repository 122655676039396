@import '../../../../../src/styles/Screens.scss';

.user-rewards-container {
    max-width: 960px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding: 60px 0;

    h1 {
        font-family: 'Literata', serif;
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 16px;
        color: #09399A;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
            margin-bottom: 16px;
        }
    }

    .user-details {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 40px;

        span {
            margin: 0;
        }

        span:first-child {
            margin-left: 0px;
        }

        span:not(:last-child)::after {
            content: " | ";
        }
    }

    .filter-by {
        margin-bottom: 40px;
        height: 48px;
        font-size: 18px;
        display: flex;
        align-items: center;

        button {
            margin-left: 16px;

            span.icon {
                margin-top: 6px;
            }
        }

        input {
            margin-left: 16px;
            height: 100%;
            border-radius: 4px;
            padding: 14px 16px;
            border: 1px solid #9E99AD;
        }
    }

    .user-info {
        display: flex;
        justify-content: space-between;

        button.secondary {
            background: inherit;
            height: 48px;
        }
    }

    .rewards-table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        padding-left: 16px;

        th,
        td {
            font-size: 18px;
            font-weight: 400;
            color: #6D6685;
            line-height: 150%;
            text-align: left;
            border-bottom: 1px solid #CECCD6;
            padding: 16px 0px;
            white-space: nowrap;

            &.reward-number {
                width: 15%;
            }

            &.patient-case-number {
                width: 15%;
            }

            &.eligibility-period {
                width: 30%;
            }

            &.date-earned {
                width: 20%;
            }
        }

        td {
            color: #6D6685;
            font-size: 14px;

            &.patient-case-title {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        th {
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }
}
