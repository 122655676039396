.search-input-container {
    position: relative;
    max-width: 400px;
    display: flex;

    .search-input {
        display: flex;
        width: 100%;
        padding: 14px 16px;
        color: #6D6685;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.09px;
    }

    >svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
