.ui-button {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 8px;
    width: fit-content;
    border: none;
    border-radius: 100px;
    -webkit-transition: background 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out, border 300ms ease-out;
    transition: background 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out, border 300ms ease-out;
    cursor: pointer;

    &:focus {
        box-shadow: 0px 0px 0px 4px #E5F0FF;
    }

    &.primary {
        background: #8651CC;
        color: #fff;

        &:hover,
        &:focus {
            background: #50317A;
        }

        &:active {
            background: #362052;
        }

        &:disabled {
            background: #E7E5EB;
            color: #6D6685;
        }
    }

    &.secondary {
        background: #fff;
        color: #8651CC;
        border: 1px solid #8651CC;

        &:hover,
        &:focus {
            color: #50317A;
            border-color: #50317A;
        }

        &:active {
            background: #F3EEFA;
            color: #8651CC;
            border-color: #8651CC;
        }

        &:disabled {
            color: #6D6685;
            border-color: #6D6685;
        }
    }

    &.icon {
        display: flex;
        background: #fff;
        padding: 0px 16px;
        width: 48px;
        height: 48px;
        border: 1px solid #8651CC;

        svg {
            color: #50317A;
        }
    }

    &.text {
        color: #8651CC;
        padding: 4px 8px;
        background-color: inherit;

        &:hover {
            color: #50317A;
        }

        &:focus {
            color: #50317A;
            border: 1px solid #50317A;
        }

        &:active {
            color: #8651CC;
            background: #F3EEFA;
        }

        &:disabled {
            color: #6D6685;
        }
    }

    &.warning {
        color: #fff;
        background: #C11700;

        &:hover,
        &:focus {
            background: #9A1907;
        }

        &:active {
            background: #690D00;

        }

        &:disabled {
            background: #E7E5EB;
            color: #6D6685;
        }
    }
}
