@import '../../../src/styles/Screens.scss';

.action-link-tiles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    padding: 0 24px 96px 24px;

    font-family: 'Lato', sans-serif;
    background: #FFFFFF;
    position: relative;

    @media only screen and (max-width: $phone-max-width) {
        flex-direction: column;
    }
}
