.tile-content {
    font-family: 'Lato', sans-serif;
    color: #0C0033;

    .tile-link {
        display: flex;
        width: 260px;
        margin: auto;

        &:hover {
            color: #0C0033;
        }

        .card {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 16px;
            border-radius: 8px;
            color: black;
            height: 308px;
            width: 260px;

            .chip {
                margin-bottom: 8px;
            }

            .tile-image,
            .title,
            .sub-title {
                margin-bottom: 8px;
            }

            .title,
            .sub-title {
                flex-grow: 1;
            }

            .tile-image {
                height: 115px;

                &.community, &.event-coverage, &.expert-on-call {
                    object-fit: contain;
                }

                &.clinical {
                    border-radius: 50%;
                    width: 50%;
                    align-self: center;
                    object-fit: unset;
                }
            }

            .title {
                font-family: 'Literata', serif;
                font-size: 18px;
                margin-top: 0px;
                font-weight: 600;
                line-height: 130%;
            }

            .sub-title {
                font-family: 'Lato', serif;
                color: #7A7F94;
                font-size: 12px;
                line-height: 120%;
            }

            .cta {
                display: flex;
                color: #8651CC;
                flex-direction: row;
                width: 100%;
                align-items: center;
                font-size: 14px;

                >svg {
                    margin-right: 5px;
                }

                >.cta-text {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
