@import '../../../src/styles/Screens.scss';

.consensus-submission-form {
    font-family: 'Lato', sans-serif;
    background: #fff;
    box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
    margin-top: 80px;
    padding: 48px;

    @media only screen and (max-width: $phone-max-width) {
        margin-top: 0px;
        padding: 40px 24px 24px 24px;
    }

    h1 {
        font-family: 'Literata', serif;
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #09399A;
        line-height: 130%;
        letter-spacing: -0.015em;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 28px;
        }
    }

    .description {
        font-size: 22px;
        margin-bottom: 16px;
        line-height: 28px;

        @media only screen and (max-width: $phone-max-width) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .instruction {
        font-size: 12px;
        margin-bottom: 80px;

        &::before {
            content: "*";
            color: #BE4335;
        }

        @media only screen and (max-width: $phone-max-width) {
            margin-bottom: 40px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #BE4335;
        }
    }

    .ui.form {
        display: flex;
        flex-direction: column;

        label {
            font-family: 'Literata', serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 130%;
            letter-spacing: -0.005em;
            color: #0C0033;
            margin-bottom: 4px;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 18px;
            }
        }

        p {
            font-size: 14px;
            margin-top: 0px;
            font-weight: 400;
            color: #6D6685;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 12px;
            }
        }

        >.input {
            margin-bottom: 80px;

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 40px;
            }
        }

        >.program-container {
            display: flex;
            margin-bottom: 40px;

            >:first-child {
                flex: 0 0 25%;
                margin-right: 16px;
            }

            >:nth-child(2) {
                flex: 1;
                align-self: center;
                font-size: 1em;
                opacity: 1;
                transition: opacity 1.5s cubic-bezier(1,-1,.5,1);
                &.error {
                    color: #BE4335;
                }
                &:empty {
                    opacity: 0;
                }
            }
        }

        >.author-container {
            display: flex;
            margin-bottom: 40px;
            flex-direction: column;

            >:first-child {
                margin-bottom: 16px;
            }

            >:not(:first-child) {
                flex: 1;
                margin-bottom: 16px;
                font-size: 1em;
                opacity: 1;
                transition: opacity 1.5s cubic-bezier(1,-1,.5,1);
                &.error {
                    color: #BE4335;
                }
                &:empty {
                    opacity: 0;
                }
            }
        }

        h2 {
            font-family: 'Literata', serif;
            font-style: normal;
            font-size: 36px;
            margin-top: 0px;
            margin-bottom: 24px;
            font-weight: 600;
            line-height: 130%;

            /* identical to box height, or 47px */
            letter-spacing: -0.01em;

            @media only screen and (max-width: $phone-max-width) {
                font-size: 24px;
            }
        }

        .opinion-analysis-information {
            margin-bottom: 16px;
            color: #6C7073;
        }

        .case-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px 32px 48px;
            border-radius: 8px;
            margin-bottom: 80px;
            background: #FFFFFF;
            border: 1px solid #EEEDF0;
            box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 40px;
                padding: 16px 16px 24px;
            }

            .case-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;
                width: 100%;

                @media only screen and (max-width: $phone-max-width) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .case-number {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    @media only screen and (max-width: $phone-max-width) {
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 16px;
                        font-size: 16px;
                    }

                    .chip {
                        margin-bottom: 0px;
                    }

                    button {
                        all: unset;
                        font-family: 'Lato', serif;
                        font-weight: 500;
                        cursor: pointer;
                        font-size: 14px;
                        line-height: 125%;
                        color: #8651CC;
                        margin-left: 8px;
                    }
                }

                .case-data {
                    color: #6C7073;
                    font-size: 14px;

                    @media only screen and (max-width: $phone-max-width) {
                        font-size: 12px;
                    }
                }
            }

            .case-body {
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                /* or 27px */
                letter-spacing: -0.005em;

                @media only screen and (max-width: $phone-max-width) {
                    font-size: 16px;
                }
            }
        }

        >textarea {
            margin-bottom: 24px;
        }

        .quote-container,
        .definitions-container,
        .references-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 24px 24px 40px;
            background: #F7F6F8;
            border: 1px solid #CECCD6;
            border-radius: 8px;
            width: 100%;
            margin-bottom: 80px;

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 40px;
                padding: 16px 16px 24px;
            }

            .quote,
            .definition {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                >label {
                    font-family: 'Literata', serif;
                    font-weight: 400;
                    color: #272A2C;
                    margin-bottom: 16px;
                    font-size: 22px;
                }

                textarea,
                .input {
                    width: 100%;
                    margin-bottom: 16px;
                }

                textarea {
                    resize: none;
                }
            }

            .remove-quote,
            .remove-definition {
                margin-bottom: 20px;
            }

            .reference {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-bottom: 20px;

                @media only screen and (max-width: $phone-max-width) {
                    flex-direction: column;
                }

                .remove-reference {
                    margin: 10px 0px 0px 10px;
                }

                .input {
                    flex-grow: 1;
                }

                .input:first-child {
                    margin-right: 16px;

                    @media only screen and (max-width: $phone-max-width) {
                        margin-right: 0px;
                        margin-bottom: 16px;
                    }
                }
            }

            &.conclusion-quotes {
                margin-bottom: 64px;

                @media only screen and (max-width: $phone-max-width) {
                    margin-bottom: 40px;
                }
            }
        }

        hr {
            margin-top: 0px;
            margin-bottom: 80px;
            border-top: 1px solid #6C7073;
            border-bottom: none;
            border-right: none;
            border-left: none;
            width: 100%;
            color: #6C7073;

            @media only screen and (max-width: $phone-max-width) {
                margin-bottom: 40px;
            }
        }

        .additional-sections {
            .section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 80px;

                @media only screen and (max-width: $phone-max-width) {
                    margin-bottom: 40px;
                }

                >.input {
                    width: 100%;
                    margin-bottom: 40px;
                }

                textarea {
                    margin-bottom: 24px;
                }

                .remove-section {
                    margin-top: 40px;
                }
            }

            .quote-container {
                margin-bottom: 0px;
            }
        }

        .button {
            margin-bottom: 48px;
        }
    }

    a.anchor {
        display: block;
        position: relative;
        top: -100px;
        visibility: hidden;
    }

    .wysiwygWrapper {
        width: 100%;
    }

    .wysiwygEditor {
        min-height: 200px;
        margin-bottom: 24px;
        padding: 0px 20px;
        border: 1px solid #DDDDDD;
    }

    .rdw-link-modal-label {
        font-size: 14px !important;
    }

    .rdw-link-modal-target-option {
        font-size: 14px !important;
        margin: 10px 0px;

        input {
            margin-top: 3px;
        }
    }

    .rdw-link-modal-buttonsection {
        margin-top: 10px;
    }

    .rdw-option-active {
        -webkit-box-shadow: 0px;
        box-shadow: 0px;
        border: 1px solid #000000;
    }

}
